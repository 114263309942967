<template>
    <div id="banner-center"></div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
/* #banner-center {
   
    border:1px solid red;
    height: 380px;
    
    background: url("../assets/banner-principal-sano.jpg") no-repeat center 90% / 90% 50% ;
    background-size: cover;
    
} */
div#banner-center {
    height: 890px;
    background: url("../assets/banner-principal-sano.jpg") no-repeat center;
    background-size: cover;
    background-position-y: 15px;
}
</style>
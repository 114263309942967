<template>
   <div id="como-funciona">
       <v-row no-gutters>
         <v-col       
           cols="12"
           sm="12"      >
<v-card
    class=""
    outlined
    tile
>
<!-- <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template> -->
    <v-row justify="center">
      <v-card-title>Como Funciona!</v-card-title>

    </v-row>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="8">
          <p class="texto-destaque">
            Sabemos que necessitamos cuidar e sermos cuidados! <br/>Como é importante contar com um profissional qualificado para recebermos todo o cuidado necessário quando mais precisamos.
            <br/>Essa é a motivação que nos faz <strong>selecionar para você os melhores profissionais e mais qualificados</strong> em atenção e cuidados especiais.</p>
            <p class="texto-destaque">
              O uso do nosso site para cadastrar e encontrar um profissional e tanto para o cadastro como profissional da área de saúde para cuidar de pessoas é <strong><em>totalmente gratuito.</em></strong> 
              <br/>Você só paga pelo Profissional que contratar, acerta diretamente com ele e paga para ele o preço que negociarem.<br> Basta realizar um simples <em>cadastro</em> para encontrar e contratar esse profissional <strong>gratuitamente</strong>, clicando no botão abaixo: </p>
              <!-- <v-btn
      class="ma-2 btn-caduser"
      :loading="loading4"
      :disabled="loading4"
      color="success"
      @click="loader = 'loading4'"
    >
      Cadastrar
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
      </template>
    </v-btn> -->
    <v-btn
      x-large
      color="success"
      class="pa-8 mt-5 btncaduser"
      elevation="2"
      dark
    >Quero encontrar um Profissional de Saúde
    </v-btn>
      </v-col>
      </v-row>
        </v-card-text>
        <v-row justify="center">
    <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn> -->
                <!-- <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="pink"
                >
                <v-icon dark>
                    mdi-heart
                </v-icon>
                 </v-btn> -->
                <!-- <v-btn icon>
                  <v-icon>mdi-bookmark</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn> -->
              </v-card-actions>
            </v-row>
           </v-card>
         </v-col>
       </v-row>
   </div>
  
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
#como-funciona {
  margin-bottom: 30px;
  font-size: larger;
}
.v-card__title {
  padding-top: 70px;
  padding-bottom: 50px;
  font-size:42px;
  color:#5ec3a1;
  }

  .texto-destaque {
    font-size:38px;
    color:#5ec3a1;
    line-height: 1.6;
    /* text-align: justify;
    text-justify: inter-word; */
    text-indent: 50px;
  }
  .v-card {
    background-color: #efecec;
  }
  .btncaduser:hover {
    background-color: #f99831 !important;
  }
</style>
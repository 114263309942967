<template>
    <div id="logo">
      <img alt="Sano Logo" src="../assets/sano-logo.jpeg">    
    </div>
</template>
<script>
    export default {
      
    }
</script>

<style scoped>
#logo {  
  float:left;
}
#logo img {
  width: 55%;
}
/* #banner {
  display: inline-block;
    border:1px solid red;
    height: 580px;
    
   
    background: url("../assets/banner-principal-sano.jpg") no-repeat center center;
} */
</style>
<template>
  <v-app>
    <v-main>
      <v-row no-gutters>
         <v-col       
           cols="12"
           sm="12"      >
           <div id="header">
             <HeaderPrincipal/>
             <MenuPrincipal/>
            </div>
            <BannerPrincipal/>
            <ChamadaPrincipal/>
            <ComoFuncionaPrincipal/>
          </v-col>
          </v-row>
    </v-main>
  </v-app>
</template>

<script>
import HeaderPrincipal from './components/HeaderPrincipal';
import BannerPrincipal from './components/BannerPrincipal';
import ChamadaPrincipal from './components/ChamadaPrincipal';
import MenuPrincipal from './components/MenuPrincipal';
import ComoFuncionaPrincipal from './components/ComoFuncionaPrincipal';

export default {
  name: 'App',

  components: {
    HeaderPrincipal,
    MenuPrincipal,
    BannerPrincipal,
    ChamadaPrincipal,
    ComoFuncionaPrincipal,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
/* CSS RESET to Vuetify */
html {
  margin:0 !important;
  padding:0 !important;
} 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5ec3a1;  
  background-color: #5ec3a1;
}
#header {
 background-color: #fff;
 height: 80px;
}


</style>

<template>
    <div>
        <div id="menu">
            <ul>
                <li class="click-hover"><a href="#" target="_self" rel="noopener noreferrer">Início</a></li>
                <li class="click-hover"><a href="#" target="_self" rel="noopener noreferrer">Quem somos?</a></li>
                <li class="click-hover"><a href="#como-funciona" target="_self" rel="noopener noreferrer">Como funciona!</a></li>
                <!-- <li class="destaque"><a href="/cadastrar-profissional" target="_self" rel="noopener noreferrer">Faça a diferença!</a></li> -->
                <li class="destaque"><a href="/cadastrar-profissional" target="_self" rel="noopener noreferrer">Quero Cuidar!</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
div#menu {
    float: right;
    left:-50px;
    padding-top:10px;
}
div#menu > ul {
    list-style-type: none;
    padding: 0;
    display: flex;
}
div#menu ul > li{     
    margin: 15px 10px;
}
div#menu li > a {
    text-decoration: none;
    font-weight: bolder;
    color:#5ec3a1;
    padding: 5px 10px;
    font-size: 20px;
}
.destaque > a {
    background-color: #5ec3a1;
    color: #ffffff !important;
    border-radius: 25% 10%;
    padding:15px 15px !important;
    height: 50px;
}
.destaque > a:hover{
    background-color: #f99831;
}
.click-hover > a:hover {
    border-bottom: 1px solid #5ec3a1;
}
</style>
<template>
    <div>
       
        <div class="chamada-banner">
            <h1 class="chamada">O PLANO é CUIDAR de QUEM VOCÊ AMA.<br/>Plena Sano. Saúde Digital.</h1>
            <button></button>
        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.chamada{
    color:#5ec3a1;
    font-weight: bolder;
    background-color: #fff;
    text-transform: uppercase;
    font-size: 36px;
}
.chamada-banner {
    background-color: #fff;
    opacity: 0.85;
    height: 50px;
    width: 950px;
    margin-top: -100px;
    float: right;
    padding-bottom: 50px;
}
</style>